@media (max-width: 768px) {
  html {
    font-size: 13px;
  }
}

.editorClassName {
  border: 1px solid $gray-300;
  border-top: 0;
  padding: 0 40px;
  .public-DraftEditor-content {
    min-height: 500px;
  }
}

.toolbarClass {
  margin-bottom: 0 !important;
}

.list-group-item-action {
  cursor: pointer;
}

.rdw-option-active {
  border: 2px solid #3154ce !important;
}

.alert-inline {
  display: inline-block;
  padding: 1rem 3rem 1rem 1.5rem;
}

h1,
.h1 {
  font-family: Poller One, monospace;
  text-transform: uppercase;
  font-size: 2rem;
  margin-bottom: 0;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: Source Sans Pro, sans-serif;
  font-weight: bold;
}

.display-1 {
  h1 {
    font-size: 2.3rem;
  }
  .subtitle {
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
    font-family: Volkhov, Arial;
    font-style: italic;
    font-weight: 400;
  }
}

.display-2 {
  font-weight: 400;
  font-size: 1.5rem;
  h2 {
    font-size: 1.8rem;
    font-family: Poller One, monospace;
    text-transform: uppercase;
  }
  .subtitle {
    font-size: 1.5rem;
    margin-bottom: 1.4rem;
    font-family: Volkhov, Arial;
    font-style: italic;
    font-weight: 400;
  }
}

.display-3 {
  font-size: 1.4rem;
}

body,
html {
  overflow-x: hidden;
  overflow-y: visible;
}

body,
p {
  font-weight: 500;
}

a,
a:not([href]) {
  font-weight: 600;
  cursor: pointer;
  color: $link-color;
}
a:hover,
a:not([href]):hover {
  color: lighten($link-color, 20%);
}

.rdw-editor-wrapper a {
  color: $default;
}

h2,
.h2 {
  font-size: 2rem;
}

footer {
  color: white;
  overflow: hidden;
  background-color: $default;
  font-size: 0.8rem;

  a {
    color: white;
    display: block;
    padding: 3px 0;
  }
}

.container-shaped {
  position: relative;
  overflow: hidden;
}

.container-shaped:after {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: -200px;
  height: 900px;
  z-index: -1;
  background: $teal;
  transform: skew(0, -3deg);
}

.section-hero {
  overflow: visible;
  background-color: transparent;
  margin-bottom: 80px;
}

.section-hero:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1600px;
  z-index: -1;
  background: $teal;
  transform: skew(0, -3deg);
}

.card-header {
  border-bottom: none;
  background-color: transparent;
  > * {
    color: $primary;
  }
}

.subtitle {
  font-size: 1.5rem;
}

.text-warning,
.text-danger,
.text-success {
  font-weight: 600;
}

img {
  max-width: 100%;
}

.bordered {
  border: 1px solid $gray-200;
  padding: 1.25rem 1.5rem;
  border-radius: $card-border-radius;
}

.clickable {
  cursor: pointer;
}

.content {
  max-width: 640px;
}
.hdrd {
  position: relative;
  margin-bottom: 1.5rem;
  padding-bottom: 1.6rem;
}
.hdrd:before {
  content: "";
  position: absolute;
  left: -1000px;
  right: -1000px;
  bottom: 0;
  height: 1000px;
  z-index: -1;
  background: $teal;
}

.hero-illu {
  flex: 0;
  margin-bottom: -160px;
  text-align: center;
}

@media (max-width: 991.98px) {
  .navbar-collapse .collapse-brand img {
    max-height: 36px;
    height: auto;
  }
  .hero-illu {
    img {
      height: 500px;
    }
  }
}

@media (max-width: 767px) {
  .section-hero {
    margin-bottom: 20px;
  }

  .hidden-md {
    display: none;
  }
  .hero-illu {
    margin-bottom: -100px;
    margin-top: 15px;
    img {
      height: 200px;
    }
  }
}

.public-DraftEditor-content,
.exprecheader,
.letter-body {
  color: black;
}

.page-device-cont {
  min-height: calc(100vh - 300px);
}

.navbar-brand img {
  height: 55px;
}

// react-phone-number-input
.PhoneInput {
  display: flex;
  align-items: center;
  border: 1px solid #c9d1d6;
  border-radius: 0.25rem;
  width: 100%;
}

.PhoneInputInput {
  flex: 1;
  border: none;
  padding: 8px 0px;
  justify-content: flex-start;
  color: #8898aa;
  font-size: 0.875rem;
}

.PhoneInputInput::placeholder {
  color: #8898aa;
  font-size: 0.875rem;
}

.PhoneInputCountryIcon {
  box-shadow: none;
  margin-left: 8px;
}

.PhoneInputInput:focus {
  border-top: 0.1px solid #cfe4f5;
  border-right: 0.1px solid #cfe4f5;
  border-bottom: 0.1px solid #cfe4f5;
  border-left: none;
  outline: none;
}

.editor-letter-header {
  flex: 100%;
}

.rdw-editor-toolbar {
  border: 0;
  padding-top: 15px;
  padding-bottom: 15px;
}
